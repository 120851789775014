.pga_containerClassName{

    display: flex;
    list-style: none;

}

.pga_pageLinkClassName , .pga_previousClassName a, .pga_nextLinkClassName{

    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    font-size: .875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8898aa;

}

.pga_breakLinkClassName{

    cursor: pointer;

}

.txt_pagination{

    font-size: 13px;
    display: flex;
    align-items: center;

}

.select_acts_pgs{

    padding: 0px !important;
    padding-left: 4px !important;
    height: 20px !important;
    margin-top: 3px !important;

}

.pga_pageClassName , .pga_nextClassName, .pga_previousClassName {

    color: #8898aa;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 50%!important;
    width: 36px;
    height: 36px;
    text-align: center;
    cursor: pointer;
    position: relative;
    margin-left: 4px;
    margin-right: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    

}

.pga_activeClassName a:hover{ color:#fff; }

.pga_activeClassName{

    z-index: 3;
    background-color: #5e72e4;
    border-color: #5e72e4;
    box-shadow: 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);
    
}

.pga_activeClassName a{ color:#fff; }
.button_showInfosInvoice{

    background-image: linear-gradient(141deg, #9fb8ad 0%, #1fc8db 51%, #2cb5e8 75%);
    position: fixed;
    top: 170px;
    left: calc(100% - 50px);
    width: 50px;
    height: 50px;
    text-align: center;
    cursor: pointer;
    border-radius: 15px 0px 0px 15px;
    box-shadow: 0px 0px 2px 0px #000;

}

.button_showInfosInvoice i{

    color: #fff;
    font-size: 24px;
    margin-top:13px

}


.div_miniinfos_invoice{

    background-color: #fff;
    box-shadow: 0px 2px 5px -3px #000;
    border-radius: 5px;
    width: 480px;
    padding: 10px;
    z-index: 1;
    
}

.div_miniinfos_invoice  {

    user-select: none;

}

.swinf_1{

    font-size:15px;
    font-weight: 700px;

}

.font_pdpq{

    font-weight: 700;

}

.expanded_infoinvoice{

    width: 0px;
    background-color: #fff;
    box-shadow: 0px 2px 5px -3px #000;
    z-index: 0;
    position: relative;
    top: 10px;
    border-radius: 5px;
    transition: 0.4s;
    height: 240px;
     

}

.expand_left_info_invoice{

    background: linear-gradient(-45deg, rgb(153, 0, 239), rgb(123, 220, 181));
    color: #FFF;
    width: 35px;
    height: 30px;
    border-radius: 5px 0px 0px 5px;
    position: absolute;
    left: -35px;
    cursor: pointer;
    bottom: 16px;

}
 

.expand_left_info_invoice i{

    margin-left: 10px;
    margin-top: 6px;

}

.infoi_invoice_div{

    position: absolute;
    display: flex;

}

.showInfosDescoto{

    font-size: 12px;

}

.showInfosDescoto:hover{

    font-size: 16px;

}
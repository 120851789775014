.erroInputBase{

	color:red;
	margin-top: 5px;

}


.imgup{

	height:100px !important;
	margin-bottom: 20px;

}

.bg-info{

	background: linear-gradient(-45deg,#7b142e,#d12646) !important;

}

.bg-gradient-info{

	background: linear-gradient(-45deg,#7b142e,#d12646) !important;
	
}

.paneUnitMove{

	background-color: #fff;
	border-radius: 10px;
	padding: 0px 15px !important;
}

.paneUnitMove .list-group-item{

    background-color: #fff0 !important;
    border: 1px solid #e9ecef00 !important;

}

.paneUnit2Move{

	background-color: #fff;
	border-radius: 0px;
	padding: 0px 15px !important;

}

.iconUnitDialog{

	max-height: 150px;
	
}

.paneLessonMove{ 

	border-radius: 15px;

}

.timeline-one-side:before{

    height: -webkit-fill-available;

}

.avatarIconCourse{

    background-color: #adb5bd00 !important;
    border-radius: 0px 0px 0px 0px !important;

}

.avatarIconCourse img{ border-radius: 0px 0px 0px 0px !important; }

.lessonInputTagSomentTag{ background-color: #fff0; }
.lessonInputTagSomentTag .tagInputClass{ display: none; }
.lessonInputTagSomentTag .removeClass::after{ display: none; }
.lessonInputTagSomentTag .removeClass{ font-size: 18px; top: 49%; }


.lessonInputTagSomentInput .tag-wrapper{ display: none; }


.rc-time-picker-input{    padding: 0 !important; border: 1px solid #f8f9fe00 !important;     color: #8898aa !important; font-size: 0.875rem !important;}

.rc-time-picker-panel-inner{     

    border: 1px solid #cccccc00 !important;
    box-shadow: 0 1px 5px #fdfdfd !important;
    color: #8898aa !important;
	padding: 0px;
    margin-top: 13px;

}

.rc-time-picker-panel-input{ color: #8898aa !important; font-size: 0.875rem !important;}

.rc-time-picker-panel-input-wrap{ border-bottom: 1px solid #e9e9e9;  padding: 2px 0px 10px 11px;}

.navbar-brand{

    padding: 0px;

}

.navbar-vertical .navbar-brand-img, .navbar-vertical .navbar-brand > img{

	max-width: 100% !important;
    max-height: 9999px !important;
    height: 88px !important;

}

.navbar-brand{

	padding-left: 2px;

}

.paneUnit3Move .ql-editor{    height: 96px;}
.paneUnit3Move { background-color: #fff; }
.paneUnit3Move svg{pointer-events: none;}


.input_edit_lesson{

	border: none;
    background-color: #f7fafc;
    color: #575863;
	font-weight: 400;
	cursor: pointer;

}


.paneLessonMove:hover{

	background-color:#efefef;

}

.paneLessonMove:hover input{

	background-color:#efefef;

}


.editandoLesson{

	background-color: #5e72e4;
	border-radius: 20px;

}

.editandoLesson input{

	background-color: #5e72e4 !important;
	color: #fff !important;

}

.editandoLesson i{ color: #fff !important; }

.editandoLesson .timeline-step{ background-color: #485abf; }

.paneLessonMove .timeline-content{ padding-top: 7px; }

.ql-align .ql-picker-label{ display:none; }    .ql-formats svg{ width: 20px; height: 20px; cursor:pointer; } 

.fontButton{

	font-size: .875rem;
	color: #adb5bd;
	margin: 0 .25rem;
	cursor: pointer;

}

.fontButton:hover{ color: #919ca6; }

.inputClientListField{

	width: 90%;
	border: none;
	box-shadow: 0px 1px 2px -2px #000;
	margin-left: 6px;
	border-radius: 8px;
	padding-left: 5px;

}

.variavel_contrato{
 
	margin-right: 3px;
	font-weight: 600;

}

.rna-wrapper div div{z-index:9999 !important;}
.report_crm{

    text-align: center;

}

.internal_report_crm{
     
    background-color: #fff;
    padding:10px;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    border-radius: 10px;
    font-weight: 600;

}

.title_crm{

    margin-bottom: 10px;

}

.chart_crm{ 
    
    height: 300px; 

}

.report_crm{ margin-bottom: 20px;}

.msgError{

    font-weight: 500;
    font-size: 13px;

}

.reactdate{ display: flex; border: 1px solid #dee2e6; border-radius: 5px;}

.reactdate input{

    border: none;
    width: 100px;

}

.reactdate i{ 
    
    margin-top:10px;

}